import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MainLanding: React.FC = () => {
  const [hoveredSection, setHoveredSection] = useState<"left" | "right" | null>(
    null
  );

  const { t } = useTranslation();

  return (
    <>
      <div className="relative w-full h-screen overflow-hidden hidden lg:block">
        {/* Left Section with Diagonal Split for Desktop, Full Height for Mobile */}
        <div
          className={`absolute inset-0 md:h-full w-full h-1/2 transition-all duration-500 ease-in-out ${
            hoveredSection === "left" ? "z-20" : "z-10"
          }`}
          onMouseEnter={() => setHoveredSection("left")}
          onMouseLeave={() => setHoveredSection(null)}
          style={{
            backgroundImage: "url('images/backgrounds/main1.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            clipPath:
              hoveredSection === "left"
                ? "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
                : "polygon(0 0, 55% 0, 45% 100%, 0 100%)",
          }}
        >
          <Link to={"/services"}>
            <div className="flex items-center justify-center md:justify-start h-full bg-black bg-opacity-50 md:px-[8%] xl:px-[calc((100vw - 1280px)/2)]">
              <h1 className="text-2xl md:text-3xl lg:text-3xl xl:text-5xl text-white font-bold text-center md:text-left break-words">
                {t("study")}
                <br />
                {t("abroad")}
              </h1>
            </div>
          </Link>
        </div>

        {/* Right Section with Diagonal Split for Desktop, Full Height for Mobile */}
        <div
          className={`absolute md:inset-0 inset-x-0 bottom-0 md:h-full w-full h-1/2 transition-all duration-500 ease-in-out ${
            hoveredSection === "right" ? "z-20" : "z-10"
          }`}
          onMouseEnter={() => setHoveredSection("right")}
          onMouseLeave={() => setHoveredSection(null)}
          style={{
            backgroundImage: "url('images/backgrounds/main2.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            clipPath:
              hoveredSection === "right"
                ? "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
                : "polygon(55% 0, 100% 0, 100% 100%, 45% 100%)",
          }}
        >
          <Link to={"/english-center"}>
            <div className="flex items-center justify-center md:justify-end h-full bg-black bg-opacity-50 px-8 md:px-32">
              <h1 className="text-2xl md:text-3xl lg:text-3xl xl:text-5xl text-white font-bold text-center md:text-right break-words">
                {t("english")}
                <br />
                {t("center")}
              </h1>
            </div>
          </Link>
        </div>
      </div>

      <div className="relative w-full h-screen overflow-hidden lg:hidden">
        {/* Left Section with Diagonal Split for Desktop, Full Height for Mobile */}
        <div
          className={`absolute inset-0 md:h-full w-full h-1/2 transition-all duration-500 ease-in-out hover:scale-110 ${
            hoveredSection === "left" ? "z-20" : "z-10"
          }`}
          onMouseEnter={() => setHoveredSection("left")}
          onMouseLeave={() => setHoveredSection(null)}
          style={{
            backgroundImage: "url('images/backgrounds/main1.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "right",
          }}
        >
          <Link to={"/services"}>
            <div className="flex items-center justify-center md:justify-start h-full bg-black bg-opacity-50 md:px-[8%] xl:px-[calc((100vw - 1280px)/2)]">
              <h1 className="text-3xl md:text-6xl text-white font-bold text-center md:text-left break-words">
                {t("study")}
                <br />
                {t("abroad")}
              </h1>
            </div>
          </Link>
        </div>

        {/* Right Section with Diagonal Split for Desktop, Full Height for Mobile */}
        <div
          className={`absolute md:inset-0 inset-x-0 bottom-0 md:h-full w-full h-1/2 transition-all duration-500 ease-in-out hover:scale-110 ${
            hoveredSection === "right" ? "z-20" : "z-10"
          }`}
          onMouseEnter={() => setHoveredSection("right")}
          onMouseLeave={() => setHoveredSection(null)}
          style={{
            backgroundImage: "url('images/backgrounds/main2.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Link to={"/english-center"}>
            <div className="flex items-center justify-center md:justify-end h-full bg-black bg-opacity-50 px-8 md:px-32">
              <h1 className="text-3xl md:text-6xl text-white font-bold text-center md:text-right break-words">
                {t("english")}
                <br />
                {t("center")}
              </h1>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default MainLanding;

import { CustomFlowbiteTheme } from "flowbite-react";

const lightTheme: CustomFlowbiteTheme = {
    button: {
      color: {
        "main-red": "bg-[#ae172a] hover:bg-[#DF0029] text-white",
        "main-blue": "bg-[#274C77] hover:bg-[#F74C77]"
      },
    },
};

export default lightTheme;
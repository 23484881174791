import React, { useEffect, useState } from "react";
import HeaderSpace from "../../../components/Shared/HeaderSpace/HeaderSpace";
import { Button, Modal, Table } from "flowbite-react";
import { Employee } from "../../../interfaces/Employee";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import Sidebar from "../../../components/Shared/Sidebar/Sidebar";
import AdminTeamTableRow from "./Table/AdminTeamTableRow";

import { FaPlus } from "react-icons/fa";
import AddTeamModal from "./Modals/AddTeamModal";

function AdminTeam() {
  const [employees, setEmployees] = useState<Employee[]>([]);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "employees"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Employee)
      );
      setEmployees(items);
    };

    fetchData();
  }, []);

  console.log(employees);

  return (
    <>
      <HeaderSpace className="bg-white h-20 lg:h-28" />
      <div className="flex flex-col lg:flex-row">
        <Sidebar />
        <div className="flex-1 py-12">
          <div className="w-10/12 mx-auto flex flex-col gap-8">
            <div className="flex flex-col lg:flex-row justify-between gap-4">
              <h2 className="text-main-red font-bold text-3xl text-center lg:text-left lg:text-5xl">
                ჩვენი გუნდი
              </h2>
              <Button
                className="flex items-center gap-2 bg-main-red"
                color={"main-red"}
                onClick={() => setOpenModal(!openModal)}
              >
                დამატება{" +"}
              </Button>
            </div>
            <div className="overflow-x-auto">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell>სურათი</Table.HeadCell>
                  <Table.HeadCell>სახელი და გვარი</Table.HeadCell>
                  <Table.HeadCell>გამოცდილება</Table.HeadCell>
                  <Table.HeadCell>რიგის ნომერი</Table.HeadCell>
                  <Table.HeadCell>
                    <span className="sr-only">Edit</span>
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {employees &&
                    employees
                      .sort((a, b) => a.order - b.order)
                      .map((employee, index) => (
                        <>
                          <AdminTeamTableRow
                            id={employee.id}
                            name={employee.name}
                            imageUrl={employee.imageUrl}
                            roles={employee.roles}
                            order={employee.order}
                            key={employee.id}
                            nameEn={employee.nameEn}
                            rolesEn={employee.rolesEn}
                          />
                        </>
                      ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <AddTeamModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}

export default AdminTeam;

import React from 'react'
import MainLanding from '../../components/Homepage/MainLanding/MainLanding'
import MainPartners from '../../components/Homepage/MainPartners/MainPartners'
import MainStudents from '../../components/Homepage/MainStudents/MainStudents'
import MainAbout from '../../components/Homepage/MainAbout/MainAbout'
import MainFAQ from '../../components/Homepage/MainFAQ/MainFAQ'
import MainBlog from '../../components/Homepage/MainBlog/MainBlog'
import MainStats from '../../components/Homepage/MainStats/MainStats'

function Home() {
  return (
    <div className=''>
        <MainLanding />
        <MainStats />
        <MainPartners />
        {/* <MainServices /> */}
        <MainStudents />
        <MainAbout />
        <MainFAQ />
        <MainBlog />
    </div>
  )
}

export default Home
import React, { useEffect, useState } from "react";
import HeaderSpace from "../../components/Shared/HeaderSpace/HeaderSpace";
import Container from "../../components/Shared/Container/Container";
import Stats from "../../components/Stats/Stats";
import { Button, Carousel } from "flowbite-react";
import EmployeeCard from "../../components/Cards/EmployeeCard";
import { Employee } from "../../interfaces/Employee";
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import Spinner from "../../components/Shared/Spinner/Spinner";
import { AboutPhoto } from "../../interfaces/AboutPhoto";
import { scrollToContact } from "../../utils";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { Certificate } from "../../interfaces/Certificate";

function About() {
  const { t, i18n } = useTranslation(); // Initialize translation hook
  const [employees, setEmployees] = useState<Employee[]>();
  const [certificates, setCertificates] = useState<Certificate[]>();
  const [aboutPhotos, setAboutPhotos] = useState<AboutPhoto[]>();
  const [aboutData, setAboutData] = useState<DocumentData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch employees data
      const querySnapshot = await getDocs(collection(db, "employees"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Employee)
      );
      setEmployees(items);

      const certQuerySnapshot = await getDocs(collection(db, "certificates"));
      const certs = certQuerySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Certificate)
      );
      setCertificates(certs);

      // Fetch about data from pages collection
      const aboutDocRef = doc(db, "pages", "about");
      const aboutDocSnapshot = await getDoc(aboutDocRef);

      if (aboutDocSnapshot.exists()) {
        setAboutData(aboutDocSnapshot.data());
      } else {
        console.log("No such document in pages/about!");
      }

      // Fetch about photos data
      const querySnapshotAbout = await getDocs(collection(db, "about"));
      const aboutItems = querySnapshotAbout.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as AboutPhoto)
      );
      setAboutPhotos(aboutItems);
    };

    fetchData();
  }, []);

  if (!employees || !aboutPhotos || !aboutData) {
    return <Spinner />;
  }

  // Determine whether to use the English or default version of the about data
  const aboutDescription =
    i18n.language === "en" ? aboutData.descriptionEn : aboutData.description;
  const aboutTitle =
    i18n.language === "en" ? aboutData.titleEn : aboutData.title;

  // Conditionally render stats based on the selected language
  const experience =
    i18n.language === "en"
      ? aboutData.stats.experienceEn
      : aboutData.stats.experience;
  const partners =
    i18n.language === "en"
      ? aboutData.stats.partnersEn
      : aboutData.stats.partners;
  const students =
    i18n.language === "en"
      ? aboutData.stats.studentsEn
      : aboutData.stats.students;

  return (
    <div
      className="relative bg-repeat animate-breathing"
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px",
      }}
    >
      <HeaderSpace />
      <Container className="py-4 lg:py-12">
        <div className="flex flex-col gap-16">
          <div className="flex flex-col text-center">
            <h3 className="text-main-blue font-bold">
              {t("whoAreWe")} {/* Translation for "ვინ ვართ ჩვენ?" */}
            </h3>
            <h2 className="text-main-red font-bold text-4xl">
              {t("aboutUs")} {/* Translation for "ჩვენ შესახებ" */}
            </h2>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 lg:gap-32">
            <div className="lg:h-full object-cover rounded-xl">
              <Carousel>
                {aboutData.images.map((img: string) => (
                  <img
                    src={img}
                    alt="about"
                    className="lg:h-full object-cover rounded-xl"
                  />
                ))}
              </Carousel>
            </div>
            <div className="flex flex-col gap-4">
              <h3
                className="font-bold text-main-blue text-center lg:text-left text-2xl lg:text-3xl"
                dangerouslySetInnerHTML={{ __html: aboutTitle }}
              />
              <h4
                className="lg:text-left text-justify"
                dangerouslySetInnerHTML={{ __html: aboutDescription }}
              />
              <Stats
                experience={experience}
                partners={partners}
                students={students}
              />
              <Button
                className="bg-main-red"
                color={"main-red"}
                onClick={scrollToContact}
              >
                {t("contactUs")} {/* Translation for "დაგვიკავშირდი" */}
              </Button>
            </div>
          </div>
        </div>
      </Container>

      <Container className="py-8">
        <div className="h-56 sm:h-64 xl:h-[512px] 2xl:h-[624px]">
          <Carousel>
            {aboutPhotos &&
              aboutPhotos.map((photo) => (
                <img
                  src={photo.imageUrl}
                  key={photo.id}
                  alt="about"
                  className="object-cover"
                />
              ))}
          </Carousel>
        </div>
      </Container>

      {/* History Section */}
      <Container className="py-12 flex flex-col gap-4">
        <h2 className="text-xl lg:text-2xl font-bold text-main-blue">
          {t("ourHistory")} {/* Translation for "ჩვენი ისტორია" */}
        </h2>
        <h4
          className="text-sm"
          dangerouslySetInnerHTML={{ __html: t("historyDescription") }}
        />
        <img
          src="/images/certificates/about-certificates.png"
          alt="certificates"
          className="max-h-32 max-w-64"
        />
      </Container>
      <Container className="py-8 flex flex-col gap-8">
        <h2 className="text-xl lg:text-2xl font-bold text-main-blue">
          {t("certificates")}
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-8">
          {certificates &&
            certificates.map((cert) => (
              <div className="flex flex-col gap-2" key={cert.id}>
                <img
                  src={cert.imageUrl}
                  alt={cert.name}
                  className="h-64 w-full object-cover"
                />
                <div className="text-center text-sm">
                  {i18n.language === "en" ? cert.nameEn : cert.name}
                </div>
              </div>
            ))}
        </div>
      </Container>
      <div className="py-12 bg-main-red">
        <Container className="flex flex-col gap-8">
          <div>
            <h4 className="text-gray-200 font-bold lg:text-lg">
              {t("meetOurTeam")} {/* Translation for "გაეცანი გუნდს" */}
            </h4>
            <h2 className="text-white font-bold text-2xl lg:text-4xl">
              {t("ourExpertTeam")}{" "}
              {/* Translation for "ჩვენ ვართ გამოცდილთა გუნდი" */}
            </h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {employees &&
              employees
                .sort((a, b) => a.order - b.order)
                .map((employee) => (
                  <EmployeeCard
                    key={employee.id}
                    // Use English fields if the language is set to "en", otherwise fallback to the default language fields
                    name={
                      i18n.language === "en"
                        ? employee.nameEn || employee.name
                        : employee.name
                    }
                    imageUrl={employee.imageUrl}
                    // Ensure roles are valid arrays before calling join
                    role={
                      i18n.language === "en"
                        ? Array.isArray(employee.rolesEn) &&
                          employee.rolesEn.length > 0
                          ? employee.rolesEn.join(", ")
                          : employee.roles.join(", ")
                        : Array.isArray(employee.roles) &&
                          employee.roles.length > 0
                        ? employee.roles.join(", ")
                        : ""
                    }
                  />
                ))}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default About;

import React, { useEffect, useState } from "react";
import Container from "../../Shared/Container/Container";
import { Accordion, Button, Spinner } from "flowbite-react";
import { HiOutlineArrowRight } from "react-icons/hi";
import { FAQ } from "../../../interfaces/FAQ";
import { collection, DocumentData, getDocs, QueryDocumentSnapshot } from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import useTranslation

function MainFAQ() {
  const { t, i18n } = useTranslation(); // Initialize translation hook with i18n for language detection
  const [FAQs, setFAQs] = useState<FAQ[]>(); // State to store FAQs

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "faq"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as FAQ)
      );
      setFAQs(items);
    };

    fetchData();
  }, []);

  if (!FAQs) {
    return <Spinner />;
  }

  return (
    <div
      className="relative bg-repeat animate-breathing"
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px",
      }}
    >
      <Container className="h-full flex flex-col justify-center relative gap-8 pb-4">
        <div>
          <h4 className="text-gray-600 text-center text-lg lg:text-xl font-bold">
            {t("faq")} {/* Translation for "F.A.Q." */}
          </h4>
          <h3 className="text-main-blue text-center text-2xl lg:text-4xl font-bold">
            {t("FAQ")} {/* Translation for "ხშირად დასმული შეკითხვები" */}
          </h3>
        </div>
        <div className="flex flex-col gap-4">
          <Accordion collapseAll className="border-main-blue bg-main-background">
            {FAQs &&
              FAQs.filter((faq, index) => index <= 2)
                .sort((a, b) => a.order - b.order)
                .map((faq) => (
                  <Accordion.Panel key={faq.id}>
                    <Accordion.Title className="text-main-blue font-bold text-sm lg:text-[16px]">
                      {/* Display question based on current language */}
                      {i18n.language === "en" ? faq.questionEn : faq.question}
                    </Accordion.Title>
                    <Accordion.Content className="text-xs lg:text-sm font-light text-gray-700">
                      {/* Render answerHTML based on current language */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: i18n.language === "en" ? faq.answerHTMLEn : faq.answerHTML,
                        }}
                      />
                    </Accordion.Content>
                  </Accordion.Panel>
                ))}
          </Accordion>
          <div className="w-full flex justify-end">
            <Link to={"/faq"}>
              <Button
                color={"main-red"}
                className="text-main-red bg-transparent flex flex-col justify-center items-center font-bold hover:bg-main-red hover:text-white"
              >
                {t("learnMore")} {/* Translation for "გაიგე მეტი" */}
                <HiOutlineArrowRight className="ml-2 h-5 w-5" />
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MainFAQ;

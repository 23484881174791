import React from 'react'
import Container from '../Shared/Container/Container'

interface StudentCardProps {
    imageUrl: string;
    name: string;
    university: string;
    description: string;
}

function StudentCard({name, university, description, imageUrl}: StudentCardProps) {
  return (
    <div className='py-8 bg-red-200 rounded-xl min-h-80 h-full w-full'>
        <Container className='flex flex-col gap-8 h-full'>
            <div className='flex items-center'>
                <img src={imageUrl} alt="student-01" className='rounded-full h-24 w-24 lg:h-32 lg:w-32' />
                <div className='ml-4'>
                    <h3 className='text-main-red font-bold'>{name}</h3>
                    <h4 className='text-main-blue font-bold text-sm'>{university}</h4>
                </div>
            </div>
            <div className='flex-1 text-gray-600 text-sm' dangerouslySetInnerHTML={{__html:description}}>
            </div>
        </Container>
    </div>
  )
}

export default StudentCard
import React, { useEffect, useState } from "react";
import StudentCard from "../../Cards/StudentCard";
import Container from "../../Shared/Container/Container";
import { Carousel, Spinner } from "flowbite-react";
import { Student } from "../../../interfaces/Student";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import { useTranslation } from "react-i18next";

function MainStudents() {
  const { t, i18n } = useTranslation(); // Initialize translation hook
  const [students, setStudents] = useState<Student[]>();

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "students"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Student)
      );
      setStudents(items);
    };

    fetchData();
  }, []);

  if (!students) {
    return <Spinner />;
  }

  return (
    <div className="bg-main-red py-24">
      <Container className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <h3 className="text-center text-gray-300 text-sm lg:text-xl">
            {t("whatAreThaySaying")} {/* Translation for "რას ამბობენ" */}
          </h3>
          <h2 className="text-center text-white text-2xl lg:text-5xl">
            {t("ourStudents")}{" "}
            {/* Translation for "ჩვენი კურსდამთავრებულები" */}
          </h2>
        </div>
        <Carousel indicators={false}>
          {students &&
            students
              .filter((student, index) => index <= 3)
              .map((student) => (
                <StudentCard
                  key={student.id}
                  name={
                    i18n.language === "en"
                      ? student.nameEn
                      : student.name /* Assuming you have different fields for each language */
                  }
                  imageUrl={student.imageUrl}
                  description={
                    i18n.language === "en"
                      ? student.descriptionHTMLEn
                      : student.descriptionHTML
                  } // Adjust description based on the language
                  university={student.jobs[0]} // Assuming jobs has language variations
                />
              ))}
        </Carousel>
      </Container>
    </div>
  );
}

export default MainStudents;

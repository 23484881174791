import React, { useEffect, useState } from "react";
import HeaderSpace from "../../components/Shared/HeaderSpace/HeaderSpace";
import Container from "../../components/Shared/Container/Container";
import ServiceHorizontalPreview from "../../components/Previews/ServiceHorizontalPreview";
import { collection, DocumentData, getDocs, QueryDocumentSnapshot } from "firebase/firestore";
import Spinner from "../../components/Shared/Spinner/Spinner";
import { Service } from "../../interfaces/Service";
import { db } from "../../firebase/firebaseConfig";
import { useTranslation } from "react-i18next"; // Import useTranslation
import SquarePreviewService from "../../components/SquarePreviewService/SquarePreviewService";

function Services() {
  const { t, i18n } = useTranslation(); // Initialize translation hook with i18n for language detection
  const [services, setServices] = useState<Service[]>();

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "services"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Service)
      );
      setServices(items);
    };

    fetchData();
  }, []);

  if (!services) {
    return <Spinner />;
  }

  return (
    <div
      className="relative bg-repeat animate-breathing"
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px",
      }}
    >
      <HeaderSpace />
      <Container className="py-4 lg:py-12 flex flex-col gap-8 lg:gap-16">
        <div className="flex flex-col">
          <div className="flex flex-col text-center">
            <h3 className="text-main-blue font-bold">
              {t("getWhatYouNeed")} {/* Translation for "Get what you really need" */}
            </h3>
            <h2 className="text-main-red font-bold text-4xl">
              {t("ourServices")} {/* Translation for "Our Services" */}
            </h2>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 lg:gap-32">
          <div className="flex flex-col gap-4">
            <h3
              className="font-bold text-main-blue text-center lg:text-left text-2xl lg:text-3xl"
              dangerouslySetInnerHTML={{ __html: t("studyingAbroad") }} // HTML translation for studying abroad
            />
            <ul className="lg:text-left list-disc translate-x-4 flex flex-col gap-4 text-sm lg:text-[16px]">
              <li>{t("consultation")}</li> {/* Translation for "Free consultations..." */}
              <li>{t("programSelection")}</li> {/* Translation for "Selection of high-ranking study programs..." */}
              <li>{t("applicationSubmission")}</li> {/* Translation for "Submission of applications..." */}
              <li>{t("visaSupport")}</li> {/* Translation for "Assistance with visa documentation..." */}
              <li>{t("grantConsultation")}</li> {/* Translation for "Consultation and assistance with grants..." */}
            </ul>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-4 gap-2">
          {services &&
              services
                .sort((a, b) => a.order - b.order)
                .map((service) => (
                  <SquarePreviewService
                    service={service}
                    key={service.id}
                  />
                ))}
          </div>
        </div>
        {/* <div className="flex flex-col gap-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-8">
            {services &&
              services
                .sort((a, b) => a.order - b.order)
                .map((service) => (
                  <ServiceHorizontalPreview
                    name={i18n.language === "en" ? service.nameEn : service.name} // Use English or Georgian based on language
                    description={i18n.language === "en" ? service.descriptionHTMLEn : service.descriptionHTML} // Use English or Georgian description based on language
                    imageUrl={service.imageUrl}
                    key={service.id}
                  />
                ))}
          </div>
        </div> */}
      </Container>
    </div>
  );
}

export default Services;

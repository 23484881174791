import React from 'react'
import HeaderSpace from '../../../components/Shared/HeaderSpace/HeaderSpace'
import Sidebar from '../../../components/Shared/Sidebar/Sidebar'
import { RiAdminFill } from "react-icons/ri";

function AdminHome() {
  return (
    <>
    <HeaderSpace className='bg-white h-20 lg:h-28'/>
    <div className='flex flex-col lg:flex-row'>
      <Sidebar />
      <div className='flex-1 flex flex-col justify-center items-center min-h-[70vh] font-bold 
      text-2xl lg:text-4xl text-gray-700'>
        <RiAdminFill className='w-64 h-64' />
        <span>ადმინის პანელი</span>
        <span className='text-sm lg:text-lg text-gray-500'>(გადადით რომელიმე ქვე-კატეგორიაზე)</span>
      </div>
    </div>
    </>
  )
}

export default AdminHome
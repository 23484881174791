import React from "react";
import Container from "../../Shared/Container/Container";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import useTranslation for translations

function MainPartners() {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <div
      className="relative bg-repeat animate-breathing" // Apply background and animation class
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px", // Initial size of the dots
      }}
    >
      {/* Container for content */}
      <Container>
        <div className="flex flex-col gap-8 lg:gap-16 py-16 lg:flex-row lg:justify-center items-center">
          <img
            src="/images/heros/universities-hero.png"
            alt="universities-hero"
            className="h-64 lg:h-[420px]"
          />
          <div className="flex flex-col gap-4">
            <h3 className="text-main-red font-bold lg:text-lg">
              {t("moreThan150PartnerUniversities")} {/* Translation for "150-ზე მეტი პარტნიორი უნივერსიტეტი" */}
            </h3>
            <h2 className="text-main-blue font-bold text-2xl lg:text-5xl">
              {t("leadingUniversitiesOnlyForYou")} {/* Translation for "მსოფლიოს წამყვანი უნივერსიტეტები მხოლოდ შენთვის" */}
            </h2>
            <h5 className="text-gray-600 text-sm lg:text-lg">
              {t("partnerUniversitiesDecription")} {/* Translation for the longer description */}
            </h5>
            <Link to={"/universities"}>
              <Button color={"main-red"} className="w-full lg:max-w-sm py-1">
                {t("findYourUniversity")} {/* Translation for "იპოვე შენთვის სასურველი უნივერსიტეტი" */}
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MainPartners;

import React, { useState } from "react";
import { Blog } from "../../../../interfaces/Blog";
import { useNavigate } from "react-router";
import { object, string } from "yup";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase/firebaseConfig";
import { Button, FileInput, Label, Modal, TextInput } from "flowbite-react";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";

interface EditBlogModal {
  blog: Blog;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function EditBlogModal({ blog, openModal, setOpenModal }: EditBlogModal) {
  const [title, setTitle] = useState(blog.title);
  const [titleEn, setTitleEn] = useState(blog.titleEn); // English title
  const [bodyHTML, setBodyHTML] = useState(blog.bodyHTML);
  const [bodyHTMLEn, setBodyHTMLEn] = useState(blog.bodyHTMLEn); // English content
  const [createDate, setCreateDate] = useState(blog.createDate);
  const [imageUrl, setImageUrl] = useState(blog.imageUrl);

  const [file, setFile] = useState<File | null>(null);

  const [errorMessages, setErrorMessages] = useState<string[]>();

  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const navigate = useNavigate();

  let blogschema = object({
    title: string().required("სათაური აუცილებელია!"),
    titleEn: string().required("Title (English) is required!"),
    imageUrl: string().required("მთავარი სურათი აუცილებელია!"),
    createDate: string().required("შექმნის თარიღი აუცილებელია"),
    bodyHTML: string().required("ტანი აუცილებელია!"),
    bodyHTMLEn: string().required("Body (English) is required!"),
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSave = async () => {
    if (!blog) {
      setErrorMessages(["No blog selected for editing."]);
      return;
    }

    try {
      let updatedImageUrl = blog.imageUrl;

      if (file) {
        const storage = getStorage();
        const storageRef = ref(storage, `images/${file.name}`);
        await uploadBytes(storageRef, file);
        updatedImageUrl = await getDownloadURL(storageRef);
      }

      await blogschema.validate({
        title,
        titleEn,
        bodyHTML,
        bodyHTMLEn,
        imageUrl: updatedImageUrl,
        createDate,
      });

      const updatedBlog: Blog = {
        id: blog.id,
        title,
        titleEn,
        bodyHTML,
        bodyHTMLEn,
        createDate,
        imageUrl: updatedImageUrl,
      };

      const blogDocRef = doc(db, "blogs", blog.id);
      await setDoc(blogDocRef, updatedBlog, { merge: true });
      await updateDoc(blogDocRef, updatedBlog as { [x: string]: any });

      setOpenModal(false);
      navigate(0); // Refresh the page or navigate as needed
    } catch (e: any) {
      console.error(e);
      let messages = e.errors;
      setErrorMessages(messages);
    }
  };

  const handleDelete = async (blogId: string) => {
    setButtonsDisabled(true);
    try {
      const blogDocRef = doc(db, "blogs", blogId);
      await deleteDoc(blogDocRef);
      navigate(0); // Refresh the page or update the state to reflect changes
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
    setButtonsDisabled(false);
  };

  return (
    <>
      <Modal
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
        className="font-mtavvruli"
      >
        <Modal.Header className="text-main-blue font-bold">
          შეცვალე <span className="text-main-red">{blog.title}</span>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6 w-full">
            <div className="flex flex-col gap-4">
              {/* Title (Georgian) */}
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="სათაური (Georgian)" />
                </div>
                <TextInput
                  id="name"
                  type="text"
                  sizing="md"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              {/* Title (English) */}
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="nameEn" value="Title (English)" />
                </div>
                <TextInput
                  id="nameEn"
                  type="text"
                  sizing="md"
                  value={titleEn}
                  onChange={(e) => setTitleEn(e.target.value)}
                />
              </div>

              {/* Creation Date */}
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="base" value="შექმნის თარიღი" />
                </div>
                <TextInput
                  id="base"
                  type="text"
                  sizing="md"
                  value={createDate}
                  onChange={(e) => setCreateDate(e.target.value)}
                />
              </div>

              {/* Body (Georgian) */}
              <HtmlEditor onChange={setBodyHTML} initialHtml={bodyHTML} />

              {/* Body (English) */}
              <HtmlEditor
                onChange={setBodyHTMLEn}
                initialHtml={bodyHTMLEn}
              />

              {/* Image Upload */}
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="file" value="ატვირთე სურათი" />
                </div>
                <FileInput
                  id="file"
                  helperText={imageUrl ? imageUrl : "სურათი"}
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
          {errorMessages &&
            errorMessages.map((message, index) => (
              <h3 key={index} className="text-main-red font-bold">
                {message}
              </h3>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="main-red"
            onClick={() => setOpenModal(false)}
            disabled={buttonsDisabled}
          >
            დახურვა
          </Button>
          <Button color="gray" onClick={handleSave} disabled={buttonsDisabled}>
            შენახვა
          </Button>
          <Button
            color="warning"
            onClick={() => handleDelete(blog.id)}
            disabled={buttonsDisabled}
          >
            წაშლა
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditBlogModal;

import React, { useState } from "react";
import { Student } from "../../interfaces/Student";
import Container from "../Shared/Container/Container";
import { Button, Modal } from "flowbite-react";

function StudentPreview({ name, imageUrl, jobs, descriptionHTML }: Omit<Student, 'nameEn' | 'descriptionHTMLEn'>) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className="hover:cursor-pointer group" onClick={() => setOpenModal(true)}>
        <Container className="flex flex-col gap-2">
          <img
            src={imageUrl}
            alt="studentImageUrl"
            className="rounded-full group-hover:scale-105 duration-100 w-40 lg:w-60"
          />
          <h3 className="text-sm lg:text-md text-center font-bold text-main-blue">
            {name.split(" ")[0]}
            <br />
            {name.split(" ")[1]}
          </h3>
        </Container>
      </div>

      <Modal dismissible show={openModal} onClose={() => setOpenModal(false)} className="font-mtavvruli">
        <Modal.Header className="font-bold">{name}</Modal.Header>
        <Modal.Body className="flex flex-col gap-8">
          <div className="flex flex-col lg:flex-row items-center gap-4">
            <img src={imageUrl} alt="imageUrl" className="rounded-full h-56 w-56" />
            <div>
              <ul className="font-bold text-main-blue list-disc translate-x-4">
                {jobs.map((job, index) => (
                  <li key={index}>{job}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="space-y-6" dangerouslySetInnerHTML={{ __html: descriptionHTML }}></div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenModal(false)}>დახურვა</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StudentPreview;

import React from "react";
import Container from "../../Shared/Container/Container";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MainBlog() {
  const { t } = useTranslation();

  return (
    <div
      className="relative bg-repeat animate-breathing py-12"
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px",
      }}
    >
      <Container className="bg-main-blue rounded-3xl py-12">
        <Container className="flex justify-between">
          <div className="flex flex-col gap-8 justify-center">
            <div className="flex flex-col h-full">
              <h4 className="text-slate-100 font-bold text-lg text-center lg:text-2xl lg:text-left">
                {t("seeOur")} {/* Translation for "ნახე ჩვენი" */}
              </h4>
              <h3 className="text-white font-bold text-3xl text-center lg:text-7xl lg:text-left">
                {t("blog")} {/* Translation for "ბლოგი" */}
              </h3>
            </div>
            <h5 className="text-gray-100 text-justify text-sm lg:text-lg lg:text-left">
              {t("blogDescription")} {/* Translation for the blog description */}
            </h5>
            <Link to={"/blog"}>
              <Button
                color={"main-red"}
                className="bg-white text-main-red font-bold w-full lg:max-w-32 hover:bg-main-red hover:text-white"
              >
                {t("read")} {/* Translation for "წაიკითხე" */}
              </Button>
            </Link>
          </div>
          <img
            src="/images/heros/image-gogo-usmens.png"
            alt="hero-01.png"
            className="hidden lg:block lg:h-[22rem] lg:w-[20rem]"
          />
        </Container>
      </Container>
    </div>
  );
}

export default MainBlog;

import React, { useState } from "react";
import { Service } from "../../interfaces/Service";
import SquareServicePreviewModal from "../SquareServicePreviewModal/SquareServicePreviewModal";

interface ISquarePreviewService {
  service: Service;
}

function SquarePreviewService({ service }: ISquarePreviewService) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        className="bg-main-red w-full h-full text-white flex flex-col items-center justify-center min-h-20
    hover:bg-red-700 hover:cursor-pointer duration-75"
        onClick={() => setOpen(!open)}
      >
        <h4 className="text-center text-sm">{service.name}</h4>
      </div>
      <SquareServicePreviewModal openModal={open} service={service} setOpenModal={setOpen} />
    </>
  );
}

export default SquarePreviewService;

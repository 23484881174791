import React, { useEffect, useState } from "react";
import HeaderSpace from "../../components/Shared/HeaderSpace/HeaderSpace";
import Container from "../../components/Shared/Container/Container";
import { Accordion } from "flowbite-react";
import Spinner from "../../components/Shared/Spinner/Spinner";
import { FAQ as IFAQ } from "../../interfaces/FAQ";
import { collection, DocumentData, getDocs, QueryDocumentSnapshot } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { useTranslation } from "react-i18next"; // Import useTranslation

function FAQ() {
  const { t, i18n } = useTranslation(); // Initialize translation hook with i18n to detect the current language
  const [FAQs, setFAQs] = useState<IFAQ[]>();

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "faq"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as IFAQ)
      );
      setFAQs(items);
    };

    fetchData();
  }, []);

  if (!FAQs) {
    return <Spinner />;
  }

  return (
    <div
      className="relative bg-repeat animate-breathing"
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px",
      }}
    >
      <HeaderSpace />
      <Container className="py-4 lg:py-12">
        <div className="flex flex-col gap-16">
          <div className="flex flex-col text-center">
            <h3 className="text-main-blue font-bold text-sm">
              {t("faqHeading")} {/* Translation for "F.A.Q." */}
            </h3>
            <h2 className="text-main-red font-bold text-3xl lg:text-4xl">
              {t("faqTitle")} {/* Translation for "Frequently Asked Questions" */}
            </h2>
          </div>
        </div>
      </Container>

      <Container className="py-12 flex flex-col gap-4">
        <h2 className="text-xl lg:text-2xl font-bold text-main-blue">
          {t("faqSubTitle")} {/* Translation for "Find the answer to any question" */}
        </h2>
        <Accordion collapseAll className="border-main-blue bg-main-background">
          {FAQs &&
            FAQs.sort((a, b) => a.order - b.order).map((faq) => (
              <Accordion.Panel key={faq.id}>
                <Accordion.Title className="text-main-blue font-bold text-sm lg:text-[16px]">
                  {/* Display the question in the appropriate language */}
                  {i18n.language === "en" ? faq.questionEn : faq.question}
                </Accordion.Title>
                <Accordion.Content className="text-xs lg:text-sm">
                  {/* Display the answer in the appropriate language */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: i18n.language === "en" ? faq.answerHTMLEn : faq.answerHTML,
                    }}
                  />
                </Accordion.Content>
              </Accordion.Panel>
            ))}
        </Accordion>
      </Container>
    </div>
  );
}

export default FAQ;

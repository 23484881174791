import React from "react";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { Footer as FT } from "flowbite-react";
import Container from "../Container/Container";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import useTranslation

function Footer() {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <>
      <FT container>
        <div className="w-full">
          <Container>
            <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
              <div className="hidden lg:block">
                <img
                  src="/images/logos/ell-logo.png"
                  className="w-20 h-20"
                  alt="ell-logo"
                />
                <h5 className="text-main-blue text-xs">
                  ELL - ი ელ ელ
                  <br />
                  {t("footerLogoText")}{" "}
                  {/* Translation for "ELL - ი ელ ელ შენი განვითარებისთვის" */}
                </h5>
              </div>
              <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
                <div>
                  <FT.Title
                    className="text-main-blue font-bold"
                    title={t("history")}
                  />
                  <FT.LinkGroup col>
                    <Link className="text-main-blue" to={"/about"}>
                      {t("aboutUs")} {/* Translation for "ჩვენ შესახებ" */}
                    </Link>
                    <Link className="text-main-blue" to={"/universities"}>
                      {t("partners")} {/* Translation for "პარტნიორები" */}
                    </Link>
                    <Link className="text-main-blue" to={"/students"}>
                      {t("students")} {/* Translation for "სტუდენტები" */}
                    </Link>
                  </FT.LinkGroup>
                </div>
                <div>
                  <FT.Title
                    className="text-main-blue font-bold"
                    title={t("contact")}
                  />
                  <FT.LinkGroup col>
                    <Link className="text-main-blue" to="/contact">
                      {t("textUs")} {/* Translation for "მოგვწერეთ" */}
                    </Link>
                  </FT.LinkGroup>
                </div>
                <div>
                  <FT.Title
                    className="text-main-blue font-bold"
                    title={t("information")}
                  />
                  <FT.LinkGroup col>
                    <Link className="text-main-blue" to="/blog">
                      {t("blog")} {/* Translation for "ბლოგი" */}
                    </Link>
                    <Link className="text-main-blue" to="/faq">
                      {t("faq")} {/* Translation for "FAQ" */}
                    </Link>
                  </FT.LinkGroup>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </FT>

      <div className="w-full m-0 bg-main-red py-4">
        <Container className="flex justify-between sm:flex sm:items-center sm:justify-between">
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Link
              to={"https://www.facebook.com/ELLstudyabroad"}
              target="_blank"
              className="text-white hover:text-main-blue"
            >
              <FT.Icon
                className="text-white hover:text-main-blue"
                icon={BsFacebook}
              />
            </Link>
            <Link
              to={"https://www.instagram.com/ellstudy/"}
              target="_blank"
              className="text-white hover:text-main-blue"
            >
              <FT.Icon
                className="text-white hover:text-main-blue"
                icon={BsInstagram}
              />
            </Link>
          </div>
          <a href="https://tally.ge" target="_blank">
            <img
              src="/images/logos/tally-logo-white.png"
              alt="tally logo white"
              className="h-12"
            />
          </a>
        </Container>
      </div>
    </>
  );
}

export default Footer;
